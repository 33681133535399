import React from 'react'

export default function Footer() {
  return (
    <div className="px-8 mx-auto text-white bg-brand">
      <div className="flex flex-col w-full py-6 md:flex-row">
        <div className="flex-auto">
          <p className="font-black text-orange-300 uppercase md:mb-6">
            Mobile & Email
          </p>
          <ul className="mb-6 list-reset">
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              New Customer / Order: <a href="tel:9944180533">9944180533</a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              Digital Promotion: <a href="tel:9865437303">9865437303</a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              Email:{' '}
              <a href="mailto:svsprinterscbe@gmail.com">
                svsprinterscbe@gmail.com
              </a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              Proprietor Email:{' '}
              <a href="mailto:svsprinterscbe@gmail.com">
                velu.svsprinters@gmail.com
              </a>
            </li>
          </ul>
        </div>

        <div className="flex-auto">
          <p className="font-black text-orange-300 uppercase md:mb-6">Social</p>
          <ul className="mb-6 list-reset">
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a href="https://www.facebook.com/profile.php?id=100007336728118&sk=photos">
                Facebook
              </a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a href="https://in.pinterest.com/printersandbags/">Pinterst</a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a href="https://twitter.com/svsprinters">Twitter</a>
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              <a href="http://in.linkedin.com/in/svsprintersandbags">
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-auto">
          <p className="font-black text-orange-300 uppercase md:mb-6">
            Address
          </p>
          <img
            src="https://svsprintersandbags.com/utility/svs-printer-bags-logo.png"
            width="240"
          />
          <ul className="mb-6 list-reset">
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              No.22, 8th St, Gandhipuram
            </li>
            <li className="inline-block mt-2 mr-2 md:block md:mr-0">
              Coimbatore, Tamil Nadu 641009
            </li>
          </ul>
        </div>
        <div className="flex-auto">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                width="100%"
                height="230"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=svs%20printer%20and%20bags&t=&z=19&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div>
            <a href="https://goo.gl/maps/3i3xXpfYyTL2" target="_blank">
              Click here for google map
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Link } from 'gatsby'

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  return (
    <>
      <nav
        className={
          'relative shadow-lg bg-brand shadow-lg flex flex-wrap bg-red-600 items-center justify-between px-2 py-1 navbar-expand-lg'
        }
      >
        <div className="container flex flex-wrap items-center justify-between px-4 mx-auto">
          <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className={
                'text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase'
              }
              href="/"
            >
              <img
                src="https://svsprintersandbags.com/utility/svs-printer-bags-logo.png"
                width="180"
                alt="asd"
              />
            </a>
            <button
              className="lg:hidden"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor "
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center lg:bg-transparent lg:shadow-none' +
              (navbarOpen ? ' block rounded shadow-lg' : ' hidden')
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
              <li className="flex items-center">
                <Link
                  className="flex items-center px-3 py-4 font-bold text-white uppercase lg:hover:text-gray-300 lg:py-2"
                  to="/marriage-bags"
                >
                  Marriage Bags
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className="flex items-center px-3 py-4 font-bold text-white uppercase lg:hover:text-gray-300 lg:py-2"
                  to="/flower-ribbon-badges"
                >
                  Ribbon Badges
                </Link>
              </li>

              {/* <li className="flex items-center">
                <Link
                  className="flex items-center px-3 py-4 font-bold text-white uppercase lg:hover:text-gray-300 lg:py-2"
                  to="/flower-ribbon-badges"
                >
                  Ribbon Badges
                </Link>
              </li> */}
              {/* <li className="flex items-center">
                <Link
                  className="flex items-center px-3 py-4 font-bold text-white uppercase lg:hover:text-gray-300 lg:py-2"
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li> */}
              <li className="flex items-center">
                <button
                  className={
                    'bg-red-600 text-white text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3'
                  }
                  type="button"
                  style={{ transition: 'all .15s ease' }}
                >
                  <a href="tel:+919944180533">Call: 9944180533</a>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
